//libs
import { styled } from '@nykaa/ui-components';

// constants

export const HeaderContainer = styled.div`
  width: 100%;
  height: 56px;
  background-color: ${({ theme }) => theme.colors.surface};
  border-radius: ${({ theme }) => `${theme.borders.radius40} ${theme.borders.radius40} 0 0`};
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
`;

export const CloseIconWrapper = styled.span`
  margin: ${({ theme }) =>
    `${theme.spacing.spacing80} 0 ${theme.spacing.spacing80} ${theme.spacing.spacing100}`};
`;
