export const NAV_KEY = 'Login Nudge';
export const TEXTS = {
  TITLE: 'Login to access your wishlist',
  SIGN_UP: 'If you are new to Nykaa Fashion',
  LOG_IN: 'If you are already registered with us, continue login using:',
  SIGN_UP_BTN: 'Signup using mobile number',
  EMAIL: 'Email id',
  MOBILE: 'Mobile number',
};
export const RIGHT_ARROW_ICON_SIZE = '20px';
