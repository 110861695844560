export const MENU_PADDING = 10;

export const NAV_STATES = {
  MENU: 'MENU',
  CATEGORY: 'CATEGORY',
  SEARCH: 'SEARCH',
};

export const HEADER_HEIGHT = 56;

export const SHOW_SEARCH_QUERY_PARAM = 'showsearch';
