// libs
import React, { memo } from 'react';

// analytics
import { onCartClick } from 'shared/components/Header/analytics';

// helpers
import { getMobileCheckoutURI } from 'shared/helpers/utils/checkout';

// styled components
import { CartButton, CartIcon, CartNumber } from './styles';

// componenets
import CartNudge from 'shared/layouts/DetailsPage/components/CartNudge';

// color
import { COLORS } from 'shared/styles/base';

//context
import { useDomainConfig } from 'shared/domainConfig/context/context';

// constants
import { CART_INTERACTION_LOCATION } from 'shared/helpers/analytics/constants/cart';
const BAG_ICON = 'BagIcon';

// defs
interface ICartProps {
  cartCount: number | string;
  showToast: boolean;
  toastMessage: string;
}

function Cart({ cartCount, showToast, toastMessage }: ICartProps) {
  const {
    COLOR: {
      CART_COUNT: { TEXT, BACKGROUND, BORDER },
    },
  } = useDomainConfig();

  const goToCart = (isToastClicked: boolean) => {
    const location = isToastClicked ? CART_INTERACTION_LOCATION.VIEW_BAG : undefined;

    onCartClick(BAG_ICON, location);
    window.location.href = getMobileCheckoutURI();
  };

  return (
    <>
      <CartButton
        onClick={() => goToCart(false)}
        className="cart"
        data-at="cart-button"
        data-test="cart-count-button"
      >
        <CartIcon color={COLORS.BLACK_92} />
        {cartCount > 0 && (
          <CartNumber color={TEXT} bg={BACKGROUND} border={BORDER}>
            {cartCount}
          </CartNumber>
        )}
      </CartButton>
      {showToast && (
        <CartNudge onClick={() => goToCart(true)} actionText={'View Bag'} content={toastMessage} />
      )}
    </>
  );
}

export default memo(Cart);
