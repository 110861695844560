// libs
import React, { memo } from 'react';
import { useHistory, useLocation } from 'react-router';

// components
import { SHEET_TRANSITION } from 'shared/components/OverlaySheet';
import Header from 'shared/components/OTPVerification/LoginNudge/component/Header';

// styles
import {
  OverlaySheet,
  PrimaryButton,
  SecondaryButton,
  SecondaryButtonContainer,
  Separator,
  ArrowIconWrapper,
  Title,
  SubTitle,
} from './styles';

// constants
import { AUTH_URI } from 'shared/constants/uri';
import { useRemoteConfig } from 'store/remoteConfigs/hooks';
import { COLORS } from 'shared/styles/base';
import { NAV_KEY, TEXTS, RIGHT_ARROW_ICON_SIZE } from './constants';

// icons
import ArrowRightIcon from '@nykaa/ui-components/Icons/arrow-right';

interface ILoginNudge {
  title?: string;
  onNudgeHide: VoidFunction;
  redirectTo?: string;
}

function LoginNudge({ title = TEXTS.TITLE, onNudgeHide, redirectTo }: ILoginNudge) {
  const { search } = useLocation();
  const history = useHistory();
  const { disabled: isEmailLoginDisabled } = useRemoteConfig('emailLogin');

  const redirectToAuth = (event: React.MouseEvent, url: string) => {
    event.preventDefault();
    const params = new URLSearchParams();

    const pathname = redirectTo ? redirectTo : window.location.pathname + search;

    params.set('redirectURL', pathname.replace('?', '@'));

    history.push(AUTH_URI.PATH + url + '&' + params.toString());
  };

  return (
    <OverlaySheet
      onHide={onNudgeHide}
      navKey={NAV_KEY}
      transitionFrom={SHEET_TRANSITION.BOTTOM}
      data-test="login-nudge"
    >
      <Header />

      <Title>{title}</Title>
      <SubTitle>{TEXTS.SIGN_UP}</SubTitle>

      <PrimaryButton onClick={(e) => redirectToAuth(e, AUTH_URI.SIGN_UP)}>
        {TEXTS.SIGN_UP_BTN}
        <ArrowIconWrapper>
          <ArrowRightIcon color={COLORS.WHITE_100} size={RIGHT_ARROW_ICON_SIZE} />
        </ArrowIconWrapper>
      </PrimaryButton>

      <Separator>
        <p className="seperate">or</p>
      </Separator>

      <div className="subtitle">{TEXTS.LOG_IN}</div>
      <SecondaryButtonContainer>
        {isEmailLoginDisabled === false && (
          <SecondaryButton onClick={(e) => redirectToAuth(e, AUTH_URI.LOGIN_VIA_EMAIL)}>
            {TEXTS.EMAIL}
          </SecondaryButton>
        )}
        <SecondaryButton onClick={(e) => redirectToAuth(e, AUTH_URI.LOGIN_VIA_MOBILE)}>
          {TEXTS.MOBILE}
        </SecondaryButton>
      </SecondaryButtonContainer>
    </OverlaySheet>
  );
}

export default memo(LoginNudge);
