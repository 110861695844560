// libs
import React, { memo, useEffect } from 'react';
import { withRouter } from 'react-router';

// component
import Portal from 'shared/components/Portal';
import Placeholder from './components/Placeholder';
import Content from './components/Content';
import AppRedirectionProvider from 'shared/components/AppRedirectionPopup/Provider';

// styles
import { Wrapper } from './styles';

// helpers
import { hideOverflowY, resetOverflowY } from 'shared/helpers/dom';

// defs
import { IGlobalSearchProps } from './types';

// contexts
import globalSearchContext from './GlobalSearchContext';

//hooks
import { useNavHelper } from 'shared/components/Header/MobileHeader/helpers';

function GlobalSearch({ onHide, navState, history }: IGlobalSearchProps) {
  const [doHide, onHideSearch] = useNavHelper(history, navState, true);

  const afterHideSearch = () => doHide && onHide();

  const className = ` ${doHide && 'hidePortal'}`;

  useEffect(() => {
    hideOverflowY(undefined, false);

    return () => {
      resetOverflowY();
    };
  });

  return (
    <Portal>
      <Wrapper
        className={className}
        onAnimationEnd={afterHideSearch}
        data-test="globalSearch-Wrapper"
      >
        <AppRedirectionProvider>
          <globalSearchContext.Provider
            value={{
              hideSearchPanel: onHideSearch,
            }}
          >
            <Content fallback={<Placeholder />} />
          </globalSearchContext.Provider>
        </AppRedirectionProvider>
      </Wrapper>
    </Portal>
  );
}

export default memo(withRouter(GlobalSearch));
