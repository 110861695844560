import React from 'react';

// styles
import { ToastBox, Content } from './styles';

interface ICartNudgeProps {
  content: string;
  actionText: string;
  onClick?: VoidFunction;
  className?: string;
}

const CartNudge = ({ content, actionText, onClick, className = '' }: ICartNudgeProps) => {
  const emptyActionText = actionText.length === 0;

  return (
    <ToastBox onClick={onClick} className={className}>
      <div className="toast-mob-container">
        <Content emptyActionText={emptyActionText} data-test="nudge-content">
          {content}
        </Content>
        {actionText && <div className="action">{actionText}</div>}
      </div>
    </ToastBox>
  );
};

export default CartNudge;
