import { connect } from 'react-redux';
import { getCartCount } from 'shared/store/app/selectors';
import { getShowToast, getToastMessage } from 'shared/store/cart/selectors';
import Cart from './Cart';

const mapStateToProps = (state: any) => ({
  cartCount: getCartCount(state),
  showToast: getShowToast(state),
  toastMessage: getToastMessage(state),
});

export default connect(mapStateToProps)(Cart);
