// libs
import React, { memo } from 'react';
import { styled } from '@nykaa/ui-components';

// global components
import Logo from 'shared/components/Logo';

// constants
import { MWEB_HEADER_HEIGHT } from 'shared/styles/base';
import { LOGO_TYPE } from 'shared/constants';

// styles
const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: ${({ theme }) => theme.elevations.shadow100};
  z-index: 2;
  background: #fff;
  height: ${MWEB_HEADER_HEIGHT}px;
`;

const LogoWrapper = styled.a`
  position: absolute;
  left: 50%;
  top: 50%;
  display: flex;
  transform: translate(-50%, -50%);
`;

function Placeholder() {
  return (
    <Container>
      <LogoWrapper className="logo" aria-label="Home" href="/">
        <Logo type={LOGO_TYPE.MWEB} />
      </LogoWrapper>
    </Container>
  );
}

export default memo(Placeholder);
