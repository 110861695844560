// libs
import { connect } from 'react-redux';

// selectors
import { getRemoteConfig } from 'shared/store/remoteConfigs/selectors';
import { isLoggedIn } from 'shared/store/app/selectors';
import { getWishlistEnabled } from 'shared/components/Wishlist/helper';

// components
import Wishlist from './wishlist';

const mapStateToProps = (state: any) => ({
  isLoggedInUser: isLoggedIn(state),
  wishlistEnabled: getWishlistEnabled(getRemoteConfig(state, 'wishlist')).mobile,
});

export default connect(mapStateToProps)(Wishlist);
