import { styled } from '@nykaa/ui-components';

export const BackIcon = styled.div`
  width: 26px;
  height: 26px;
  display: inline-block;
  margin-right: ${({ theme }) => theme.spacing.spacing80};
  background: #eee;
`;
export const SearchPlaceHolder = styled.div`
  width: 126px;
  height: 26px;
  display: inline-block;
  background: #eee;
`;

export const Separator = styled.p`
  display: block;
  margin: 0;
  height: 1px;
  background: rgba(0, 0, 0, 0.12);
`;

export const Container = styled.section`
  background: #fff;
`;

export const Input = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.spacing40};
  background-color: #f6f7f9;
  border-radius: ${({ theme }) => theme.borders.radius10};
  height: 40px;
  margin: ${({ theme }) => theme.spacing.spacing40};
  position: relative;
`;
