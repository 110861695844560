// libs
import { keyframes } from '@emotion/core';
import { styled } from '@nykaa/ui-components';

// components
import RippleIt from 'shared/components/RippleIt';
import IOElement from 'shared/components/IOElement';

// constants
import { MWEB_HEADER_ZINDEX, FONTS, MWEB_HEADER_HEIGHT } from 'shared/styles/base';

export const IObserver = styled(IOElement)`
  position: absolute;
  top: 0;
  height: 1px;
  width: 100vw;
`;

export const headerFadeIn = keyframes`
    from {
        background: rgba(255, 255, 255, 0);
    }
    to {
        background: rgba(255, 255, 255, 0.7);
    }
`;

export const headerFadeOut = keyframes`
    from {
        background: rgba(255, 255, 255, 0.7);
    }
    to {
        background: rgba(255, 255, 255, 0);
    }
`;

export const HeaderContainer = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  font-family: ${FONTS.BASE_FAMILY};
  color: ${({ theme }) => theme.colors.primary};
  background: ${({ theme }) => theme.colors.surface};
  z-index: ${MWEB_HEADER_ZINDEX};
  width: 100vw;
  height: ${MWEB_HEADER_HEIGHT}px;

  &.merge {
    background: ${({ theme }) => theme.colors.surface};
    box-shadow: none;

    &.animate {
      animation: ${headerFadeOut} 0.3s ease-out forwards;
    }
  }

  &.merge-scrolled {
    animation: ${headerFadeIn} 0.3s ease-out forwards;
  }

  &.show-border {
    border-bottom: 1px solid ${({ theme }) => theme.colors.textOutline};
  }
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing.spacing80} ${theme.spacing.spacing80}`};
  height: ${MWEB_HEADER_HEIGHT}px;
  & > .actions {
    display: flex;
    margin-left: auto;
  }

  .a2hs-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const ActionButton = styled(RippleIt)`
  display: flex;
  width: auto;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.borders.radiusFull};
  background: rgba(255, 255, 255, 0.4);
  flex-shrink: 0;
  &.back-button {
    width: auto;
  }
  &:first-of-type {
    margin-left: 0;
  }

  & > .menu__icon {
    font-size: 24px;
    color: ${({ theme }) => theme.colors.textPrimary};
  }

  & > .menu__icon__back {
    font-size: 24px;
    color: ${({ theme }) => theme.colors.textPrimary};
  }
`;

export const SearchButton = styled(ActionButton)`
  font-size: 24px;
  width: 24px;
  svg {
    fill: ${({ theme }) => theme.colors.textPrimary};
  }
`;

export const LogoAnchor = styled.a<{ showLogoOnLeft: boolean }>`
  svg {
    fill: ${({ theme }) => theme.colors.textPrimary};
  }
  ${({ showLogoOnLeft, theme }) =>
    showLogoOnLeft
      ? `padding-left: ${theme.spacing.spacing60};
        display: flex;
        flex: 1 1 0%;
        -webkit-box-align: center;
        align-items: center;
                `
      : `position: absolute;
        height: 100%;
        text-decoration: none;
        display: flex;
        align-items: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);`}
`;
