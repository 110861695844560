/* eslint-disable react-hooks/exhaustive-deps */
// libs
import React, { memo, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

// global components
import Logo from 'shared/components/Logo';

// components
import HeaderApi from 'shared/components/Header/HeaderApi';
import Search from 'shared/components/GlobalSearch/Mobile';
import Cart from './components/Cart';
import Placeholder from './components/Placeholder';
import Wishlist from './components/Wishlist';

// contexts
import { useMobileActionContext } from 'shared/components/Header/MobileAction/Context';

// constants
import { HOME_URI, MEGA_MENU_V2 } from 'shared/constants/uri';
import { NAV_STATES, SHOW_SEARCH_QUERY_PARAM } from './constants';
import { LOGO_TYPE, TAB_INDEX } from 'shared/constants';

// helpers
import { canGoBack } from 'shared/helpers/history';

// styles
import {
  ActionButton,
  Content,
  HeaderContainer,
  IObserver,
  LogoAnchor,
  SearchButton,
} from './styles/header';

// icons
import SearchIcon from '@nykaa/ui-components/Icons/search';

// analytics
import { trackBackButtonClicked } from 'shared/components/Header/analytics';

// defs
import { IHeaderProps } from './types';

// icons
import HamburgerMenuIcon from '@nykaa/ui-components/Icons/hamburger-menu';
import ArrowLeftIcon from '@nykaa/ui-components/Icons/arrow-left';

// color
import { COLORS } from 'shared/styles/base';
import { useRemoteConfig } from 'shared/store/remoteConfigs/hooks';

const goBack = () => {
  trackBackButtonClicked();
  if (canGoBack()) {
    window.history.back();
  } else {
    window.location.href = HOME_URI;
  }
};

let timer: number | null = null;

const SEARCH_ICON_SIZE = '24px';

function Header({
  showHamburger = true,
  showBack = false,
  showLogo = true,
  showSearch = true,
  showCart = true,
  headerMerge = false,
  showBottomBorder = true,
  MobileActions,
  HeaderContent,
  showLogoOnLeft = false,
  showWishlist = true,
}: IHeaderProps) {
  const [headerFadeIn, setHeaderFadeIn] = useState(false);
  const [scrolledOnce, setScrolledOnce] = useState(false);
  const { setShowSearchCallback } = useMobileActionContext();
  const isHamburgerMenuEnabled = useRemoteConfig('showHamburgerMenu') && showHamburger;
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // Clean browser history stack in case user reloaded when on MENU
  // Order of clearing matters

  useEffect(() => {
    if (history.location.state !== undefined) {
      if (history.location.state[NAV_STATES.CATEGORY] !== undefined) {
        history.goBack();
      }
      if (history.location.state[NAV_STATES.MENU] !== undefined) {
        history.goBack();
      }
      if (history.location.state[NAV_STATES.SEARCH] !== undefined) {
        history.goBack();
      }
    }
  }, [history]);

  useEffect(() => {
    if (headerMerge) {
      setHeaderFadeIn(false);
    }
  }, [headerMerge]);

  const showMenu = () => {
    history.push(MEGA_MENU_V2);
  };
  const showSearchPage = () => {
    searchParams.set(SHOW_SEARCH_QUERY_PARAM, `1`);
    history.push({
      pathname: location.pathname,
      search: `${searchParams.toString()}`,
      state: {
        showSearch: true,
      },
    });
  };
  const hideSearchPage = () => {
    if (history.location.state && history.location.state.showSearch) {
      history.goBack();
      return;
    }
    searchParams.delete(SHOW_SEARCH_QUERY_PARAM);
    history.push({
      pathname: location.pathname,
      search: `${searchParams.toString()}`,
    });
  };
  useEffect(() => {
    setShowSearchCallback(showSearchPage);
  }, [setShowSearchCallback]);

  const onHidden = () => {
    timer !== null && window.clearTimeout(timer);
    timer = window.setTimeout(() => {
      setHeaderFadeIn(true);
      setScrolledOnce(true);
    }, 200);
  };
  const onVisible = () => {
    timer !== null && window.clearTimeout(timer);
    setHeaderFadeIn(false);
  };
  const logoClick = (e) => {
    e.preventDefault();

    window.location.href = '/';
  };
  const headerClassName = (
    headerMerge
      ? [
          headerFadeIn && 'merge-scrolled',
          headerFadeIn === false && 'merge',
          headerFadeIn === false && scrolledOnce && 'animate',
          // header is merged - dont show bottom border
          headerFadeIn && showBottomBorder && 'show-border',
        ]
      : [showBottomBorder && headerFadeIn && 'show-border']
  )
    .filter(Boolean)
    .join(' ');

  const canShowSearchPage = searchParams.get(SHOW_SEARCH_QUERY_PARAM) === '1';

  return (
    <>
      <HeaderApi shouldCallMenuApi={false} placeholder={<Placeholder />}>
        {(headerMerge || showBottomBorder) && (
          <IObserver onVisible={onVisible} onHidden={onHidden} />
        )}
        <HeaderContainer className={headerClassName}>
          <Content>
            {isHamburgerMenuEnabled && (
              <ActionButton data-cy="menu" onClick={showMenu} data-at="hamburger-button">
                <HamburgerMenuIcon color={COLORS.BLACK_92} />
              </ActionButton>
            )}
            {showBack && (
              <ActionButton onClick={goBack} className="back-button">
                <ArrowLeftIcon />
              </ActionButton>
            )}
            {HeaderContent !== undefined && HeaderContent}
            {showLogo && (
              <LogoAnchor
                onClick={logoClick}
                showLogoOnLeft={showLogoOnLeft}
                aria-label="logo"
                data-at="logo-button"
                href={HOME_URI}
              >
                <Logo type={LOGO_TYPE.MWEB} />
              </LogoAnchor>
            )}

            <div className="actions">
              {showSearch && (
                <SearchButton
                  tabIndex={TAB_INDEX.ACTION_INVISIBILITY}
                  onClick={showSearchPage}
                  data-at="search-btn"
                >
                  <SearchIcon size={SEARCH_ICON_SIZE} />
                </SearchButton>
              )}

              {MobileActions !== undefined && MobileActions}

              {showWishlist && <Wishlist />}

              {showCart && <Cart />}
            </div>
          </Content>
        </HeaderContainer>
        {canShowSearchPage && <Search onHide={hideSearchPage} navState={NAV_STATES.SEARCH} />}
      </HeaderApi>
    </>
  );
}

export default memo(Header);
