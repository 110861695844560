// libs
import React, { useContext, memo } from 'react';

// context
import { OverlaySheetContext } from 'shared/components/OverlaySheet';

// hook
import useTheme from '@nykaa/ui-components/themes/hooks/useTheme';

// types
import { Theme } from '@nykaa/ui-components/themes/types';

// styles
import {
  HeaderContainer,
  CloseIconWrapper,
} from 'shared/components/OTPVerification/LoginNudge/component/Header/styles';

// icons
import CloseIcon from '@nykaa/ui-components/Icons/close';

function Header() {
  const { doHide } = useContext(OverlaySheetContext);
  const theme: Theme = useTheme();

  return (
    <HeaderContainer>
      <CloseIconWrapper onClick={doHide}>
        <CloseIcon color={theme.colors.textPrimary} />
      </CloseIconWrapper>
    </HeaderContainer>
  );
}

export default memo(Header);
