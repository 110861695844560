// libs
import React, { memo } from 'react';

// Components
import ChunkFallback from 'shared/components/ChunkFallback';

// styles
import { Container, BackIcon, Separator, SearchPlaceHolder, Input } from './styles';

function Placeholder() {
  return (
    <Container data-test="GlobalSearchPlaceholder">
      <Input>
        <BackIcon />
        <SearchPlaceHolder />
      </Input>
      <Separator />
      <ChunkFallback />
    </Container>
  );
}

export default memo(Placeholder);
