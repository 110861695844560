// libs
import { styled } from '@nykaa/ui-components';

// styles
import { resetButton } from 'shared/styles/mixin/core';

// components
import BaseButton from 'shared/components/Button';
import BaseOverlaySheet from 'shared/components/OverlaySheet';

export const OverlaySheet = styled(BaseOverlaySheet)`
  width: 100%;
  background-color: #fff;
  top: initial;
  animation-duration: 0.3s;
  border-radius: ${({ theme }) => `${theme.borders.radius40} ${theme.borders.radius40} 0 0`};
  padding: ${({ theme }) =>
    `${theme.spacing.spacing240} ${theme.spacing.spacing100} ${theme.spacing.spacing100} ${theme.spacing.spacing100}`};
`;

export const Title = styled.div`
  ${({ theme }) => theme.typography.type241};
  margin: ${({ theme }) => `${theme.spacing.spacing40} 0`};
`;

export const SubTitle = styled.div`
  ${({ theme }) => theme.typography.bodyLarge};
`;

export const PrimaryButton = styled(BaseButton)`
  ${({ theme }) => theme.typography.buttonMedium};
  justify-content: space-between;
  padding: ${({ theme }) => `${theme.spacing.spacing80} ${theme.spacing.spacing80}`};
  margin: ${({ theme }) => `${theme.spacing.spacing80} 0`};
  height: 48px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.inverse};
`;

export const ArrowIconWrapper = styled.span`
  display: flex;
  align-items: center;
`;

export const Separator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.textOutline};
  color: ${({ theme }) => theme.colors.textSecondary};
  width: 100%;
  margin: ${({ theme }) => `${theme.spacing.spacing100} 0 ${theme.spacing.spacing60} 0`};
  height: 1px;

  & .seperate {
    background: #fff;
    padding: ${({ theme }) => `0 ${theme.spacing.spacing40}`};
  }
`;

export const SecondaryButtonContainer = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spacing.spacing80};

  & button:nth-child(2) {
    margin-left: ${({ theme }) => theme.spacing.spacing40};
  }
`;

export const SecondaryButton = styled.button`
  ${resetButton}
  border: solid 1px ${({ theme }) => theme.colors.textOutline};
  ${({ theme }) => theme.typography.subTitleLarge};
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.colors.textPrimary};
  height: 48px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => `0 ${theme.spacing.spacing80}`};
  cursor: pointer;
`;
