// libs
import React, { memo, useState } from 'react';
import { useHistory } from 'react-router';

//components
import { WishlistIcon, WishlistButton } from './style';
import LoginNudge from 'shared/components/OTPVerification/LoginNudge';

// constants
import { WISHLIST as WHISLIST_PATHNAME } from 'shared/constants/uri';
import { WISHLIST_INTERACTION } from 'shared/helpers/analytics/constants/wishlist';
import { TAB_INDEX } from 'shared/constants';

// defs
interface IWishlist {
  wishlistEnabled: boolean;
  isLoggedInUser: boolean;
}

//helpers
import { useAuthModalWrapperContext } from 'shared/components/AuthModal/AuthModalWrapperContext';
import NFA from 'shared/helpers/analytics/NFAnalytics';

// color
import { COLORS } from 'shared/styles/base';
import { LOGIN_LOCATIONS } from 'shared/helpers/analytics/constants/loginEvents';

function Wishlist({ wishlistEnabled, isLoggedInUser }: IWishlist) {
  const history = useHistory();

  const [shouldShowLoginNudge, setShowLoginNudge] = useState(false);

  const { showBottomSheetAuth } = useAuthModalWrapperContext();

  const hideLoginNudge = () => {
    setShowLoginNudge(false);
  };
  const wishlistClicked = () => {
    const data = {
      wishlistInteractionLocation: WISHLIST_INTERACTION.WISHLIST_ICON,
      pageviewLoacation: `${NFA.pageProperties.pagename}: ${LOGIN_LOCATIONS.WISHLIST_ICON_TOP_NAV}`,
    };

    NFA.updateDatalayerBeforeNext(data);

    if (!isLoggedInUser) {
      NFA.updateDataLayer({
        loginPage: '',
        loginLocation: LOGIN_LOCATIONS.WISHLIST_ICON_TOP_NAV,
      });

      showBottomSheetAuth(WHISLIST_PATHNAME, false);
    } else {
      history.push(WHISLIST_PATHNAME);
    }
  };

  if (!wishlistEnabled) {
    return null;
  }

  return (
    <>
      <WishlistButton tabIndex={TAB_INDEX.ACTION_INVISIBILITY} onClick={wishlistClicked} data-at="wishlist-button">
        <WishlistIcon color={COLORS.BLACK_92} />
      </WishlistButton>
      {shouldShowLoginNudge && (
        <LoginNudge onNudgeHide={hideLoginNudge} redirectTo={WHISLIST_PATHNAME} />
      )}
    </>
  );
}

export default memo(Wishlist);
