import { styled } from '@nykaa/ui-components';
import { FONTS } from 'shared/styles/base';

import { ActionButton } from 'shared/components/Header/MobileHeader/styles/header';
import ShoppingBag from '@nykaa/ui-components/Icons/shopping-bag';

export const CartToast = styled.div`
  display: flex;
  position: absolute;
  padding: ${({ theme }) => theme.spacing.spacing50};
  right: 10px;
  border-radius: ${({ theme }) => theme.borders.radius10};
  background-color: #494949;
  color: #fff;
  margin-top: ${({ theme }) => theme.spacing.spacing40};
  opacity: 0.8;
  top: 50px;

  &::after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: ${({ theme }) => theme.borders.radius10};
    background-color: #494949;
    transform: rotate(45deg);
    right: 15px;
    top: -5px;
  }

  & > .toast-text {
    vertical-align: top;
    margin: ${({ theme }) => `0 0 0 ${theme.spacing.spacing20}`};
    font-family: ${FONTS.BASE_FAMILY};
  }
`;

export const CartButton = styled(ActionButton)`
  position: relative;
  ${({ theme }) => theme.typography.bodyXSmall};
  margin: ${({ theme }) => `0 0 0 ${theme.spacing.spacing40}`};
  display: flex;
  align-items: center;
  width: 24px;
  margin-left: ${({ theme }) => theme.spacing.spacing120};
  svg {
    fill: ${({ theme }) => theme.colors.textPrimary};
  }

  overflow: visible;
`;

export const CartIcon = styled(ShoppingBag)`
  position: relative;
  margin-top: ${({ theme }) => `-${theme.spacing.spacing10}`};
`;

export const CartNumber = styled.span<{ color: string; bg: string; border: string }>`
  position: absolute;
  ${({ color, bg, border }) => `
    background: ${bg};
    color: ${color};
    border: 1.5px solid ${border};
  `};
  border-radius: ${({ theme }) => theme.borders.radiusFull};
  display: flex;
  justify-content: center;
  height: 16px;
  width: 16px;
  top: 0;
  align-items: center;
  ${({ theme }) => theme.typography.labelSmall};
  right: -0.25rem;
  padding-left: 1px;
  font-family: ${FONTS.BASE_FAMILY};
  ${({ theme, bigCart }) => bigCart && theme.typography.bodySmall};
  ${({ bigCart }) =>
    bigCart &&
    `
    width: 20px;
    height: 20px;
    top: -0.25rem;
    left: 0.50rem;
  `}
`;
