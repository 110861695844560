/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';
import { ICartState } from './types';

export const cart = (state: { cart: ICartState }): ICartState => state.cart;

export const getCartCount = createSelector([cart], (cart_data): number => cart_data.cartCount);

export const getShowToast = createSelector(
  [cart],
  (cart_data): boolean => cart_data.showMobileToast
);

export const getToastMessage = createSelector(
  [cart],
  (cart_data): string => cart_data.mobileToastText
);
