// libs
import { keyframes } from '@emotion/core';
import { styled } from '@nykaa/ui-components';

// styles
import { FONTS, MWEB_HEADER_ZINDEX } from 'shared/styles/base';

const slideIn = keyframes`
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
`;

const slideOut = keyframes`
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(100%);
    }
`;

export const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 2;
  transform: translateX(100%);
  animation: ${slideIn} 0.3s ease-in forwards;
  font-family: ${FONTS.BASE_FAMILY};
  color: ${({ theme }) => theme.colors.textPrimary};
  z-index: ${MWEB_HEADER_ZINDEX + 1};

  &.hidePortal {
    animation: ${slideOut} 0.2s ease-out forwards;
  }
`;
