import { styled } from '@nykaa/ui-components';
import { keyframes } from '@emotion/core';

const fadeInUp = keyframes`
    from {
        transform: translate3d(0,200px,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
}`;

export const Content = styled.div<{ emptyActionText: boolean }>`
  width: ${(props) => (props.emptyActionText ? '100%' : 'auto')};
  text-align: ${(props) => (props.emptyActionText ? 'center' : 'left')};
`;

export const ToastBox = styled.div`
  position: fixed;
  bottom: 10%;
  left: 0;
  width: 100%;
  z-index: 10;
  padding: ${({ theme }) => `0 ${theme.spacing.spacing60}`};

  .toast-mob-container {
    padding: ${({ theme }) => `0 ${theme.spacing.spacing80}`};
    border-radius: ${({ theme }) => theme.borders.radius10};
    background-color: ${({ theme }) => theme.colors.inverse};
    box-sizing: border-box;
    height: 44px;
    margin: 0px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    animation: ${fadeInUp} 600ms ease;

    ${({ theme }) => theme.typography.bodyMedium};
  }

  .action {
    text-decoration: none;
    ${({ theme }) => theme.typography.subTitleLarge};
    color: ${({ theme }) => theme.colors.viewBagColor};
  }
`;
