// libs
import { styled } from '@nykaa/ui-components';

// components
import { ActionButton } from 'shared/components/Header/MobileHeader/styles/header';

// icons
import Heart from '@nykaa/ui-components/Icons/heart';

export const WishlistButton = styled(ActionButton)`
  position: relative;
  ${({ theme }) => theme.typography.bodyXSmall};
  margin: ${({ theme }) => `0 0 0 ${theme.spacing.spacing40}`};
  display: flex;
  justify-items: center;
  align-items: center;
  width: 24px;
  margin-left: ${({ theme }) => theme.spacing.spacing120};

  svg {
    fill: ${({ theme }) => theme.colors.textPrimary};
  }
`;

export const WishlistIcon = styled(Heart)``;
