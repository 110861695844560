// helpers
import { setNewCartHeaders } from 'shared/api/helpers';
import { setCookie } from 'shared/helpers/utils';

// constants
import { MOBILE_CHECKOUT_URI } from 'shared/constants/uri';

const NEW_MWEB_CART_COOKIE = 'NYKAA_NEW_MWEB_CART';
const NEW_DWEB_CART_COOKIE = 'NYKAA_NEW_DWEB_CART';

export const getMobileCheckoutURI = () => MOBILE_CHECKOUT_URI;

export const clearMwebNewCartBucketUser = () => setCookie(NEW_MWEB_CART_COOKIE, '1', -1);

export const clearDwebNewCartBucketUser = () => setCookie(NEW_DWEB_CART_COOKIE, '1', -1);

/**
 * this sets the api headers for new cart on browser, based on user segment cookie
 *
 * TODO - remove this once we have completely migrated cart from magento to cart platform
 */
const setApiCartHeaders = () => {
  if (__BROWSER__ === false) {
    return;
  }

  // TODO - remove clear cookie code on 30-Dec-2021
  clearMwebNewCartBucketUser();
  clearDwebNewCartBucketUser();

  setNewCartHeaders();
};

setApiCartHeaders();
